import { createApp } from "vue";
import router from "./router";
import App from './App.vue'
import './style.css'

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyAr6CjnTKNVeaBAQt-wrRkzPAdX9xC_SK8",
    authDomain: "lge-airsolution-installer.firebaseapp.com",
    projectId: "lge-airsolution-installer",
    storageBucket: "lge-airsolution-installer.appspot.com",
    messagingSenderId: "114726042507",
    appId: "1:114726042507:web:6e43da9cc9a00718b80d8e",
    measurementId: "G-WWLW8B9M2D"
};


// Initialize Firebase
const fireBaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(fireBaseApp);

const app = createApp(App);
app.use(router);

//라우터가 변수를 인식하기전에 마운트되는것을 방지하기위해 마운트시점을 늦춤
router.isReady().then(() => {
    app.mount('#app')
})
// app.mount("#app");
console.log(' -------- Firebase Analytics log --------', analytics);
