<template>
  <div>
    <h1>lge AirSolution Installer GUIDE</h1>
    <hr />
    <p>
      go do Appstore
    </p>
  </div>
</template>

<script>
export default{
  name: "Home",
}
</script>
